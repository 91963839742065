body {
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #0a0b2e 0%, #26094b 100%);
  min-height: 100vh;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.container::before,
.container::after {
  content: '';
  position: fixed;
  border-radius: 50%;
  filter: blur(40px);
  z-index: -1;
}

.container::before {
  width: 400px;
  height: 400px;
  background: rgba(255, 0, 128, 0.15);
  top: -150px;
  right: -200px;
}

.container::after {
  width: 350px;
  height: 350px;
  background: rgba(0, 128, 255, 0.15);
  top: 70%;
  left: -150px;
}

.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60px;
  width: auto;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.nav-link:hover {
  opacity: 1;
}

.get-start-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  backdrop-filter: blur(10px);
}

.hero-section {
  text-align: center;
  margin-bottom: 4rem;
}

.hero-title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2196F3, #ff00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3rem;
  line-height: 1.5;
}

.hero-section::before {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  top: 10%;
  right: 5%;
  animation: rotate 20s linear infinite;
}

.floating-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.2;
}

.dot-1 { top: 20%; left: 10%; animation: float 4s ease-in-out infinite; }
.dot-2 { top: 30%; right: 15%; animation: float 6s ease-in-out infinite; }
.dot-3 { bottom: 25%; left: 20%; animation: float 5s ease-in-out infinite; }

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.dropzone {
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 3rem;
  text-align: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.dropzone p {
  margin: 0;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
}

.dropzone .hint {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.dropzone:hover, .dropzone.active {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.4);
}

.dropzone-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #4CAF50;
}

.dropzone-text {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
}

.choose-file-btn {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
}

.max-size-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  margin-top: 1rem;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  backdrop-filter: blur(10px);
}

.settings {
  margin-bottom: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 30px;
  backdrop-filter: blur(10px);
  flex-wrap: wrap;
}

.quality-control,
.compress-control {
  display: flex;
  align-items: center;
}

.quality-control label,
.compress-control label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: white;
}

.quality-control input[type="range"] {
  width: 100px;
  accent-color: #7c3aed;
}

.quality-control span {
  min-width: 40px;
}

.compress-control input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  accent-color: #7c3aed;
}

.convert-button {
  background: linear-gradient(135deg, #4CAF50, #45a049);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 150px;
}

.convert-button:hover:not(:disabled) {
  background: linear-gradient(135deg, #45a049, #3d8b40);
  transform: translateY(-1px);
}

.convert-button:disabled {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.5), rgba(69, 160, 73, 0.5));
  cursor: not-allowed;
  transform: none;
}

.download-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
}

.download-button:hover {
  background-color: #1976D2;
}

.converting {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.progress-bar {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #fff;
  transition: width 0.3s ease;
}

.hint {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin: 1rem 0;
}

.file-list {
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  gap: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.file-item {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  transition: transform 0.2s;
}

.file-item:hover {
  transform: translateY(-2px);
}

.file-preview {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.file-info {
  padding: 0.25rem;
}

.file-name {
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-size {
  font-size: 0.6rem;
  color: rgba(255, 255, 255, 0.6);
}

.remove-file {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ff4444;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0;
  line-height: 1;
  transition: all 0.2s;
}

.remove-file:hover {
  background: #ff0000;
  transform: scale(1.1);
}

.heic-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  border-radius: 4px;
  position: relative;
}

.heic-placeholder::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-top-color: #666;
  border-radius: 50%;
  margin-top: 24px;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.seo-content {
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: left;
  backdrop-filter: blur(10px);
}

.seo-content h2 {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.seo-content h3 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin: 1.5rem 0 1rem;
}

.seo-content p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.seo-content ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.seo-content li {
  color: rgba(255, 255, 255, 0.7);
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.dropzone::before,
.dropzone::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), transparent);
  border-radius: 20px;
  z-index: -1;
}

.dropzone::before {
  top: -20px;
  left: -20px;
  transform: rotate(30deg);
}

.dropzone::after {
  bottom: -20px;
  right: -20px;
  transform: rotate(-30deg);
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 0.5rem;
  margin: 2rem 0;
  width: 100%;
}

.preview-item {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.preview-loading {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
}

.preview-loading .spinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.preview-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  text-align: center;
  padding: 1rem;
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 1;
}

.remove-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.faq-section {
  margin: 4rem 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.faq-section > div {
  max-width: 800px;
  margin: 0 auto;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  background: linear-gradient(135deg, #2196F3, #ff00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.faq-container {
  width: 100%;
}

.faq-section h3 {
  margin: 2rem 0 1rem;
  font-size: 1.5rem;
  color: #2196F3;
}

.faq-section ul {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.faq-section ul li {
  margin: 0.8rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.faq-section p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 1rem 0;
}

.faq-item {
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item:hover {
  border-color: rgba(33, 150, 243, 0.3);
}

.faq-question {
  width: 100%;
  padding: 1.2rem;
  background: transparent;
  border: none;
  color: white;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background: rgba(255, 255, 255, 0.05);
}

.faq-question.active {
  background: rgba(33, 150, 243, 0.1);
}

.faq-icon {
  font-size: 1.5rem;
  min-width: 24px;
  margin-left: 1rem;
}

.faq-answer {
  padding: 1rem 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.main-footer {
  margin-top: 4rem;
  padding: 2rem 0;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 35px 1fr 90px;
  align-items: center;
  gap: 1rem;
}

.footer-text {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  grid-column: 2;
}

.footer-links {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.footer-link:hover {
  color: rgba(255, 255, 255, 0.9);
}

@media screen and (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .footer-logo {
    grid-column: 1;
    grid-row: 1;
  }

  .footer-links {
    grid-column: 1;
    grid-row: 2;
    align-items: center;
  }

  .footer-text {
    grid-column: 1;
    grid-row: 3;
  }

  .settings {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
  }

  .quality-control {
    width: 100%;
    justify-content: center;
  }

  .compress-control {
    width: 100%;
    justify-content: center;
  }

  .file-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
  }

  .file-preview {
    aspect-ratio: 1;
  }

  .file-name {
    font-size: 0.9rem;
    padding: 0.3rem 0;
  }

  .file-size {
    font-size: 0.8rem;
  }

  .file-item {
    padding: 0.5rem;
  }
}

.seo-section {
  margin: 4rem 0;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  backdrop-filter: blur(10px);
}

.seo-section > div {
  max-width: 800px;
  margin: 0 auto;
}

.seo-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2196F3, #ff00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.seo-section > div > p:first-of-type {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}

.seo-section h3 {
  margin: 2rem 0 1rem;
  font-size: 1.5rem;
  color: #2196F3;
}

.seo-section ul {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.seo-section ul li {
  margin: 0.8rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.seo-section p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 1rem 0;
}

.policy-content {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.policy-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #2196F3, #ff00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.policy-content h2 {
  font-size: 1.5rem;
  margin: 2rem 0 1rem;
  color: #2196F3;
}

.policy-content p {
  line-height: 1.6;
  margin: 1rem 0;
}

.policy-content ul {
  list-style-type: disc;
  margin: 1rem 0;
  padding-left: 2rem;
}

.policy-content li {
  margin: 0.5rem 0;
}